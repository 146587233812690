import Vue from 'vue'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import i18n from './i18n'
import { RecycleScroller, DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import localforage from 'localforage'
import TextHighlight from 'vue-text-highlight'
import VueSignature from 'vue-signature-pad'

Vue.config.productionTip = false
Vue.config.lang = 'fr'

Vue.use(VueSignature)

Vue.component('RecycleScroller', RecycleScroller)
Vue.component('DynamicScroller', DynamicScroller)
Vue.component('DynamicScrollerItem', DynamicScrollerItem)

Vue.component('text-highlight', TextHighlight)

localforage.config({
    storeName: 'proposition' // Should be alphanumeric, with underscores.
})

new Vue({
    router,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app')
