export default {
    title: 'Réglages',
    cancel: 'Annuler',
    url: 'Adresse Serveur',
    user: 'Nom d´utilisateur',
    pw: 'Mot de passe',
    wrongPin: 'Code incorrect',
    lang: 'Langue',
    signature: 'Activer Signature',
    removeOrders: 'Effacer toutes les commandes',
    uploadOnlySigned: 'Télécharger uniquement signé'
}
