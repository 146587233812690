export default {
    title: 'Settings',
    cancel: 'Cancel',
    url: 'Server URL',
    user: 'Username',
    pw: 'Password',
    wrongPin: 'Wrong Pin',
    lang: 'Language',
    signature: 'Activate Signature',
    removeOrders: 'Remove all Orders',
    uploadOnlySigned: 'Upload only signed'
}
