export default {
    title: 'Astellungen',
    cancel: 'Zréck',
    url: 'Server Adress',
    user: 'Benotzer',
    pw: 'Passwuert',
    wrongPin: 'Falschen Pin',
    lang: 'Sprooch',
    signature: 'Ennerschreft aktivéieren',
    removeOrders: 'All Bestellungen läschen',
    uploadOnlySigned: 'Nëmmen ënnerschriwwen eroplueden'
}
