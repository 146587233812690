import Vue from 'vue'
import Router from 'vue-router'
import VentesApp from './views/VentesApp.vue'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'Frontpage',
            component: VentesApp
        },
        {
            path: '/clients',
            name: 'clients',
            component: () => import('./views/Clients.vue')
        },
        {
            path: '/edit',
            name: 'edit',
            component: () => import('./views/Edit.vue')
        },
        {
            path: '/summary',
            name: 'summary',
            component: () => import('./views/Summary.vue')
        },
        {
            path: '/device',
            name: 'device',
            component: () => import('./views/Device.vue')
        },
        {
            path: '/settings',
            name: 'settings',
            component: () => import('./views/Settings.vue')
        },
        {
            path: '/sync',
            name: 'sync',
            component: () => import('./views/Sync.vue')
        }
    ]
})
