export default {
    order: 'Commande',
    import: 'Importéieren',
    sync: 'Synchronisatioun',
    settings: 'Astellungen',
    device: 'Apparat Informatiounen',
    error: 'Dir hurt net synchroniséiert Donnéeën, w.e.g synchroniséiert dei',
    newDocument: 'Dréck + fir eng nei Commande ze erstellen',
    piece: 'Stéck(er)',
    upload: 'Eroplueden',
    uploaded: 'Eropgelueden ',
    from: ' vun ',
    noImport: 'Keng Dokumenter fir ze importéieren'
}
