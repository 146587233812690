export default {
    order: 'Commande',
    import: 'Importer',
    sync: 'Synchronisation',
    settings: 'Réglages',
    device: 'Information appareil',
    error: 'Vous avez des commandes non synchroniséés, veuillez synchroniser les commandes s.v.p',
    newDocument: 'Taper + pour créer une nouvelle commande',
    piece: 'Pièce(s)',
    upload: 'télécharger',
    uploaded: 'Téléchargé ',
    from: ' de ',
    noImport: 'Aucun document à importer'
}
