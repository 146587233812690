<template>
    <v-navigation-drawer
        v-model="show"
        app
        temporary
        fixed
    >
        <v-card class="gradient">
            <v-list two-line>
                <v-list-item>
                    <v-list-item-action>
                        <img
                            class="logo"
                            src="../assets/Order.svg"
                        >
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title :class="$style.wiges">
                            WIGES
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <p :class="$style.appname">
                                VENTES APP
                            </p>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
        <v-list>
            <v-list-item
                @click="checkOrder()"
            >
                <v-icon>sync</v-icon>
                {{ $t("front.sync") }}
            </v-list-item>
            <v-list-item
                to="/settings"
            >
                <v-icon>settings</v-icon>
                {{ $t("front.settings") }}
            </v-list-item>
            <v-list-item
                to="/device"
            >
                <v-icon>smartphone</v-icon>
                {{ $t("front.device") }}
            </v-list-item>
            <div
                class="version"
                @click="reload"
            >
                Version: {{ version }}
            </div>
        </v-list>
        <v-snackbar
            v-model="snackbar"
            bottom
            vertical
            :timeout="3000"
        >
            {{ $t("front.error") }}
        </v-snackbar>
    </v-navigation-drawer>
</template>

<script>
import pkg from '../../package.json'

export default {
    name: 'settingsDrawer',
    props: {
        items: {
            type: Array,
            default () {
                return []
            }
        }
    },
    data () {
        return {
            version: pkg.version,
            show: false,
            snackbar: false
        }
    },
    methods: {
        open () {
            this.show = true
        },
        reload () {
            location.reload(true)
        },
        checkOrder () {
            if (this.items.length > 0) {
                this.snackbar = true
            } else {
                window.location = '#/sync'
            }
        }
    }
}
</script>

<style module>
.wiges{
    margin-top: 15px;
    padding-right: 55px;
    font-weight: bold;
    letter-spacing: 2.5px;
    font-size: 20px;
    color:white;
}
.appname{
    letter-spacing: 2px;
    padding-right: 22px;
    font-weight: 600;
    color: white;
}
</style>
