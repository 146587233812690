export default {
    title: 'Synchronisation',
    sync: 'Synchroniser',
    resync: 'Résync',
    clients: 'Télécharger clients ...',
    articles: 'Télécharger articles ...',
    prices: 'Télécharger prices ...',
    packages: 'Télécharger packages ...',
    sales: 'Télécharger sales ...',
    remoteSettings: 'Télécharger paramètres à distance  ...',
    return: 'Retourner',
    finish: 'Terminé !',
    error: 'Erreur',
    loading: 'télécharger'
}
