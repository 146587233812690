<template>
    <div>
        <v-app
            id="inspire"
            class="ventesapp"
        >
            <v-layout
                class="orderContainer"
                column
                fill-height
            >
                <header>
                    <div class="gradient" />
                    <v-app-bar
                        color="black"
                        dark
                    >
                        <v-btn
                            v-if="isOnline"
                            @click="openDrawer"
                            icon
                        >
                            <icon
                                name="fa/solid/bars"
                                class="barIcon"
                                color="white"
                            />
                        </v-btn>
                        <span class="text-h6">
                            {{ $t("front.order") }}
                        </span>
                    </v-app-bar>
                </header>
                <settingsDrawer
                    v-if="isOnline"
                    ref="drawer"
                    :items="orders"
                />
                <orderList
                    class="orderList"
                    :isOnline="isOnline"
                    :items="orders"
                    @updateOrders="onUpdateOrders($event)"
                />
            </v-layout>
        </v-app>
    </div>
</template>
<script>
import orderList from '../components/orderList'
import settingsDrawer from '../components/settingsDrawer'
import uuidv4 from 'uuid/v4'
import Icon, { register as registerIcons } from '@wisol/icons'
import BarsIcon from '@wisol/icons/src/icons/fa/solid/bars'

registerIcons(BarsIcon)

export default {
    name: 'VentesApp',
    components: {
        orderList,
        settingsDrawer,
        Icon
    },
    data () {
        return {
            isOnline: false,
            settings: {
                apiUsername: null,
                apiPassword: null,
                apiUrl: null,
                apiDeviceKey: null,
                pincode: '94437',
                signature: false
            },
            orders: []
        }
    },
    created () {
        window.addEventListener('beforeunload', this.saveToLocalStorage)
        this.loadFromLocalStorage()
        this.saveToLocalStorage()

        setInterval(() => {
            navigator.onLine ? this.isOnline = true : this.isOnline = false
        }, 100)

        const orders = JSON.parse(localStorage.getItem('orders'))
        if (orders) {
            this.orders = orders.map((order, index) => {
                return {
                    id: index,
                    order: order
                }
            })
        }
    },
    mounted () {
        this.checkSettings()
    },
    methods: {
        openDrawer () {
            const drawer = this.$refs.drawer
                .open()
            return drawer
        },
        checkSettings () {
            // check if settings are filled, if not then go to settings page
            if (navigator.onLine === true) {
                if (!this.settings.apiUsername || !this.settings.apiPassword || !this.settings.apiUrl) {
                    window.location.href = '#/settings'
                }
            }
        },
        showStatus (online) {
            this.isOnline = online
        },
        onUpdateOrders ({ newOrders }) {
            if (newOrders.length > 0) {
                localStorage.setItem('orders', JSON.stringify(newOrders))
            } else {
                localStorage.removeItem('orders')
            }
            this.orders = newOrders.map((order, index) => {
                return {
                    id: index,
                    order: order
                }
            })
        },
        saveToLocalStorage () {
            // check PinCode, if empty, then set default
            if (!this.settings.pincode) {
                this.settings.pincode = '94437'
            }
            // check apiDeviceKey, if empty, then set one
            if (!this.settings.apiDeviceKey) {
                this.settings.apiDeviceKey = uuidv4()
            }
            // save to localStorage
            localStorage.setItem('settings', JSON.stringify(this.settings))
        },
        loadFromLocalStorage () {
            // load localSettings
            const settings = localStorage.getItem('settings')
            if (settings) {
                this.settings = {
                    ...this.settings,
                    ...JSON.parse(settings)
                }
            }
        }
    }
}
</script>

<style>
.ventesapp {
    height: 100vh;
    width: 100vw;
}
.orderContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}
.orderContainer header {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}
.orderContainer .orderList {
    overflow: auto;
    flex-grow: 1;
}
.gradient{
  background: linear-gradient(90deg, #CC6633, #B9131D, #781345, #442254);
  flex: 0 0 10px;
}
.logo {
    width: 60px;
    padding-top: 10px;
}
.version {
    position: fixed;
    bottom: 20px;
    margin-left: 20px;
    font-size: 0.8em;
}
.barIcon {
    height: 18px;
    width: 18px;
}
</style>
