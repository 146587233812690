export default {
    order: 'Order',
    import: 'Import',
    sync: 'Synchronization',
    settings: 'Settings',
    device: 'Device Information',
    error: 'You have unsynced orders, please commit them',
    newDocument: 'Press + to create a new Order',
    piece: 'Piece(s)',
    upload: 'Upload',
    uploaded: 'Uploaded ',
    from: ' from ',
    noImport: 'No documents to import'
}
