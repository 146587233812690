export default {
    title: 'Synchronisation',
    sync: 'Synchronisieren',
    resync: 'Wiederholen',
    clients: 'Kunden herunterladen ...',
    articles: 'Artikel herunterladen ...',
    prices: 'Preise herunterladen ...',
    packages: 'Pakete herunterladen ...',
    sales: 'Reduzierungen herunterladen ...',
    remoteSettings: 'Remoteeinstellungen herunterladen ...',
    return: 'Zurückgehen',
    finish: 'Fertig !',
    error: 'Fehler',
    loading: 'wird heruntergeladen'
}
