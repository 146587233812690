export default {
    title: 'Einstellungen',
    cancel: 'Zurück',
    url: 'Server Adresse',
    user: 'Benutzer',
    pw: 'Passwort',
    wrongPin: 'Falscher Pin',
    lang: 'Sprache',
    signature: 'Unterschrift aktivieren',
    removeOrders: 'Alle Bestellungen löschen',
    uploadOnlySigned: 'Nur signiert hochladen'
}
