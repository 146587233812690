export default {
    title: 'Synchronization',
    sync: 'Synchronising',
    resync: 'Resync',
    clients: 'Download clients ...',
    articles: 'Download articles ...',
    prices: 'Download prices ...',
    packages: 'Download packages ...',
    sales: 'Download sales ...',
    remoteSettings: 'Download remotesettings ...',
    return: 'Return',
    finish: 'Finished !',
    error: 'Error',
    loading: 'Loading'
}
