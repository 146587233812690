<template>
    <div class="orderListContainer">
        <v-list
            three-line
            class="order-list"
        >
            <span
                class ="textSize20"
                v-if="this.items.length === 0"
            >
                {{ $t("front.newDocument") }}
            </span>
            <RecycleScroller
                class="scroller"
                :items="items"
                :item-size="89"
                key-field="id"
                v-slot="{ item }"
            >
                <v-list-item
                    class="user"
                    :class="{'successfully': item.order.options.valid}"
                    :disabled="item.order.block"
                    @click="editOrder(item)"
                >
                    <v-list-item-content>
                        <v-list-item-title
                            class ="textSize15 font-weight-bold"
                        >
                            {{ item.order.fullName }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ getAdresse(item.order)}}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-list-item-subtitle class ="textSize20">
                            {{ item.order.date }}
                        </v-list-item-subtitle>
                        <v-list-item-title
                            v-if="showPrices"
                            class="piecesClass font-weight-bold"
                        >
                            {{ (item.order.price).toFixed(2) }} €
                        </v-list-item-title>
                    </v-list-item-action>
                </v-list-item>
                <v-divider />
            </RecycleScroller>
        </v-list>
        <v-snackbar
            v-model="notification"
            top
            :color="color"
        >
            {{ notificationText }}
            <v-btn
                small
                fab
                dark
                class="rightFloat"
                @click="notification = false"
            >
                <v-icon color="red">
                    close
                </v-icon>
            </v-btn>
        </v-snackbar>
        <v-dialog
            v-model="loading"
            persistent
            max-width="290"
        >
            <v-card>
                <v-card-text>
                    <v-progress-circular
                        indeterminate
                        :size="100"
                        color="red"
                    />
                    <div>
                        {{ $t("front.upload") }}
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-footer
            class="orderListFooter"
            height="60px"
        >
            <v-spacer />
            <v-btn
                v-if="isOnline"
                :disabled="!allowImport"
                @click="importDocs()"
                color="black"
                class="white--text"
            >
                {{ $t("front.import") }}
            </v-btn>
            <v-spacer />
            <v-btn
                v-if="isOnline"
                :disabled="disableUpload"
                @click="upload()"
                color="black"
                class="white--text"
            >
                {{ $t("front.upload") }}
            </v-btn>
            <v-spacer />
            <v-btn
                v-if="!hideAddNewOrder"
                fab
                small
                color="pink"
                to="/clients"
                :disabled="!this.clients"
            >
                <v-icon color="white">
                    add
                </v-icon>
            </v-btn>
        </v-footer>
    </div>
</template>

<script>
import axios from 'axios'
import localforage from 'localforage'
import uuidv4 from 'uuid/v4'
import sha1 from 'sha1'
import moment from 'moment'

export default {
    name: 'orderList',
    props: {
        isOnline: {
            type: Boolean,
            default: false
        },
        items: {
            type: Array,
            default () {
                return []
            }
        }
    },
    data () {
        return {
            infos: {
                date: '',
                fullName: '',
                price: '',
                order: []
            },
            clients: [],
            infoDialog: false,
            loading: false,
            settings: {},
            notification: false,
            allowImport: false,
            disableUpload: true,
            showPrices: false,
            hideAddNewOrder: false,
            color: '',
            notificationText: '',
            propItems: []
        }
    },
    created () {
        this.loadFromLocalStorage()

        this.propItems = this.items

        // load clients from localStorage
        localforage.getItem('clients', (err, value) => {
            this.clients = value
            if (err !== null) {
                // eslint-disable-next-line
                console.log(err)
            }
        })

        localforage.getItem('remoteSettings', (err, value) => {
            if (err !== null) {
                this.remoteSettings = []
                // eslint-disable-next-line
                    console.log(err)
            } else {
                this.remoteSettings = value
                for (const prop in value) {
                    value[value[prop].name] = value[prop].value
                    delete value[prop]
                }
            }
            if (this.remoteSettings.ORDER_PROPOSAL_IMPORT_ORDER) {
                this.allowImport = this.remoteSettings.ORDER_PROPOSAL_IMPORT_ORDER
            }
            if (this.remoteSettings.ORDER_PROPOSAL_SHOW_PRICES) {
                this.showPrices = this.remoteSettings.ORDER_PROPOSAL_SHOW_PRICES
            }
            if (this.remoteSettings.ORDER_PROPOSAL_HIDE_ADDNEWORDER) {
                this.hideAddNewOrder = this.remoteSettings.ORDER_PROPOSAL_HIDE_ADDNEWORDER
            }
        })

        switch (this.settings.uploadOnlySigned) {
            case true:
                if (this.items.length > 0) {
                    this.disableUpload = !this.items.some(item => item.order.options.valid)
                }
                break
            case false:
                if (this.items.length > 0) {
                    this.disableUpload = false
                }
                break
            default:
                this.disableUpload = false
        }

        // axios settings
        axios.defaults.baseURL = this.settings.apiUrl
        axios.defaults.headers.common['Wisol-Api-Device-Key'] = this.settings.apiDeviceKey
        axios.defaults.headers.common['Wisol-Api-App-Key'] = 'f6345e3e-4956-3fb4-91ca-404f62d115ec'
    },
    computed: {
        clientMap () {
            const clientMap = new Map()

            this.clients.forEach(function (item) {
                clientMap.set(item.client, item)
            })

            return clientMap
        }
    },
    methods: {
        getAdresse (item) {
            if (item.adresse_livraison) {
                return item.adresse_livraison
            } else if (item.clientInfo.street && item.clientInfo.zip && item.clientInfo.city) {
                return item.clientInfo.street + ' ' + item.clientInfo.zip + ' ' + item.clientInfo.city
            }
            return ''
        },
        editOrder (item) {
            localforage.setItem('editOrder', item)
                .then(() => {
                    window.location = '#/edit'
                })
        },
        async upload () {
            this.loading = true

            const payloads = []
            try {
                for (const item of this.items) {
                    const listArticle = []
                    for (const article of item.order.order) {
                        let unitPrice = 0
                        if (article.item && article.item.deliveryDateList && article.item.deliveryDateList.length > 0) {
                            for (const delivery of article.item.deliveryDateList) {
                                if (article.quantity[delivery].quantity !== 0) {
                                    listArticle.push({
                                        // id: article.id, // ignore id, always create new pos ???
                                        article: article.article,
                                        commentaire: article.comment,
                                        date_livraison: article.item,
                                        unite_prix: article.item.unitPrice || unitPrice,
                                        quantite: article.quantity[delivery],
                                        unite_quantite: article.package.form
                                    })
                                }
                            }
                        } else {
                            if (article.item) {
                                unitPrice = article.item.unitPrice
                            }
                            listArticle.push({
                                id: article.id,
                                article: article.article,
                                commentaire: article.comment,
                                date_livraison: item.order.date,
                                quantite: article.quantity[0],
                                unite_prix: unitPrice,
                                unite_quantite: article.package.form,
                                optional: article.optional
                            })
                        }
                    }

                    const listPayment = []
                    if (item.order.options.selectedPayment) {
                        listPayment.push({
                            type: item.order.options.selectedPayment.type,
                            amount: item.order.price
                        })
                    }

                    payloads.push({
                        client: item.order.clientInfo.client,
                        date: item.order.date,
                        documentBase64: item.order.options.signature,
                        source: item.order.source,
                        list_payment: listPayment,
                        list_article: [
                            ...listArticle
                        ]
                    })
                }
            } catch (error) {
                const failed = 'Something went wrong at creating the payload: '
                const infos = {
                    message: failed + error.message,
                    status: 'failed'
                }
                this.triggerNotification(infos)
                this.loading = false
                throw new Error(failed)
            }

            const newOrders = []
            let uploaded = 0
            let timeout = false
            try {
                for (const payload of payloads) {
                    if (timeout) {
                        break
                    }
                    const index = payloads.indexOf(payload)
                    let allowUpload = true

                    switch (this.settings.uploadOnlySigned) {
                        case true:
                            if (this.propItems[index].order.options.valid === false) {
                                newOrders.push(this.propItems[index].order)
                                allowUpload = false
                                break
                            } else {
                                if (!this.propItems[index].order.block) {
                                    this.propItems[index].order.block = true
                                    this.propItems[index].order.uuid = uuidv4()
                                    localStorage.setItem('orders', JSON.stringify([
                                        ...newOrders,
                                        ...this.propItems.slice(index).map(item => item.order)
                                    ]))
                                }
                            }
                            break
                        case false:
                        default:
                            if (!this.propItems[index].order.block) {
                                this.propItems[index].order.block = true
                                this.propItems[index].order.uuid = uuidv4()
                                localStorage.setItem('orders', JSON.stringify([
                                    ...newOrders,
                                    ...this.propItems.slice(index).map(item => item.order)
                                ]))
                            }
                    }

                    const uuid = this.propItems[index].order.uuid

                    if (allowUpload) {
                        try {
                            const payloadJson = JSON.stringify([payload])
                            // axios request
                            await axios({
                                url: axios.defaults.baseURL + '/module/api/sync/' + uuid,
                                auth: {
                                    username: this.settings.apiUsername,
                                    password: this.settings.apiPassword
                                },
                                method: 'post',
                                data: {
                                    method: 'POST',
                                    payload: payloadJson,
                                    sha1: sha1(payloadJson),
                                    uuid: uuid,
                                    urn: '/module/orderproposal/api/syncOrders'
                                }
                            })
                                .then((response) => {
                                    uploaded++
                                    const infos = {
                                        message: this.$t('front.uploaded') + uploaded + this.$t('front.from') + payloads.length,
                                        status: 'success'
                                    }
                                    this.triggerNotification(infos)
                                })
                                .catch((error) => {
                                    let message = error.message
                                    if (error.response) {
                                        message = error.response.data.errors[0].message
                                    }
                                    const infos = {
                                        message: message,
                                        status: 'failed'
                                    }
                                    this.triggerNotification(infos)

                                    if (error.response && error.response.status && error.response.status !== 408) {
                                    // @error
                                        this.propItems[index].order.block = false
                                        newOrders.push(this.propItems[index].order)
                                    } else {
                                    // @timeout
                                        timeout = true
                                        for (const item of this.propItems) {
                                            newOrders.push(item.order)
                                        }
                                    }
                                })
                                .finally(() => {
                                    localStorage.setItem('orders', JSON.stringify([
                                        ...newOrders,
                                        ...this.propItems.slice(index + 1).map(item => item.order)
                                    ]))
                                })
                        } catch (error) {
                        // eslint-disable-next-line
                        console.log(error)
                        }
                    }
                }
            } catch (error) {
                const infos = {
                    message: error.message,
                    status: 'failed'
                }
                this.triggerNotification(infos)
            } finally {
                this.loading = false
                this.updateOrders(newOrders)
            }
        },
        updateOrders (newOrders) {
            this.$emit('updateOrders', {
                newOrders: newOrders
            })
        },
        async importDocs () {
            this.loading = true

            // axios request to get Orders
            try {
                await axios({
                    url: axios.defaults.baseURL + '/module/orderproposal/api/importOrders',
                    auth: {
                        username: this.settings.apiUsername,
                        password: this.settings.apiPassword
                    },
                    method: 'post',

                    data: {
                        where: {}
                    },
                    responseType: 'json'
                })
                    .then((response) => {
                        const importOrders = response.data
                        if (importOrders.length > 0) {
                            this.setImportedOrders(importOrders)
                        } else {
                            this.loading = false
                            const infos = {
                                message: this.$t('front.noImport'),
                                status: 'failed'
                            }
                            this.triggerNotification(infos)
                        }
                        return importOrders
                    })
            } catch (error) {
                // eslint-disable-next-line
                console.log(error)
                this.loading = false
                let message = error.message
                if (error.response) {
                    message = error.response.data.errors[0].message
                }
                const infos = {
                    message: message,
                    status: 'failed'
                }
                this.triggerNotification(infos)
            }
        },
        setImportedOrders (importOrders) {
            for (const item of importOrders) {
                const data = JSON.parse(item.data)
                const options = {
                    valid: false,
                    saved: true,
                    signatureValidate: false
                }

                let source = {}
                if (item.source) {
                    source = JSON.parse(item.source)
                }

                let price = 0
                item.list_article.forEach((list) => {
                    price = +price + (Number(list.quantite) * Number(list.unite_prix))
                })

                const clientInfo = this.clientMap.get(item.client)
                const clientID = { client: item.client, fullName: data.client_ligne1 }
                const listArticle = []

                for (const article of item.list_article) {
                    listArticle.push({
                        id: article.id,
                        article: article.article,
                        comment: article.commentaire,
                        unite_prix: article.unite_prix,
                        package: {
                            firme: '1',
                            article: article.article,
                            form: article.unite_quantite,
                            unitQuantity: '1',
                            formName: article.unite_quantite,
                            dropDownName: '"PCE (1) PIECE     "',
                            id: 0
                        },
                        quantity: [Number(article.quantite)],
                        optional: article.optional
                    })
                }

                let adresseLivraison = null
                if (data.livraison_ligne3 !== null) {
                    adresseLivraison = data.livraison_ligne3 + ' ' + data.livraison_ligne4
                }
                let orderList = {
                    block: false,
                    clientInfo: clientInfo ?? clientID,
                    date: moment(item.date).format('YYYY-MM-DD HH:mm:ss'),
                    fullName: data.client_ligne1,
                    adresse_livraison: adresseLivraison,
                    street: data.client_ligne3 ?? data.client_ligne1,
                    price: price,
                    uuid: uuidv4(),
                    options: options,
                    source: source,
                    order: listArticle
                }

                const oldOrders = JSON.parse(localStorage.getItem('orders'))
                if (oldOrders !== null) {
                    oldOrders.push(orderList)
                    localStorage.setItem('orders', JSON.stringify(oldOrders))
                } else {
                    orderList = [orderList]
                    localStorage.setItem('orders', JSON.stringify(orderList))
                }
                location.reload()
            }
        },
        triggerNotification (infos) {
            this.notification = true
            if (infos.status === 'failed') {
                this.notificationText = 'Error: ' + infos.message
                this.color = 'error'
            } else {
                this.notificationText = infos.message
                this.color = 'success'
            }
        },
        loadFromLocalStorage () {
            // load settings from localStorage
            const settings = localStorage.getItem('settings')
            if (settings) {
                this.settings = {
                    ...this.settings,
                    ...JSON.parse(settings)
                }
            }
        }
    }
}
</script>
<style >
.order-list {
    flex-grow: 1;
    overflow: auto;
    padding: 0;
}
.scroller {
  height: 100%;
}
.rightFloat {
    float: right;
}
.piecesClass {
    line-height: auto;
    height: 30px;
    font-size: 30px;
}
.orderListContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
}
.orderListFooter {
    flex-shrink: 0;
    height: 60px;
    flex-basis: 60px;
    min-height: 60px;
}
.textSize20 {
    font-size: 20px;
}
.textSize15 {
    font-size: 15px;
}
.successfully {
    background-color: greenyellow;
}
</style>
