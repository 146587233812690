export default {
    title: 'Synchronisatioun',
    sync: 'Synchroniséieren',
    resync: 'Resync',
    clients: 'Clienten eroflueden ...',
    articles: 'Artikelen eroflueden ...',
    prices: 'Präisser eroflueden ...',
    packages: 'Pakéiter eroflueden ...',
    sales: 'Sales eroflueden ...',
    remoteSettings: 'Remote Astellungen eroflueden ...',
    return: 'Zréck',
    finish: 'Fäerdeg !',
    error: 'Fehler',
    loading: 'get erofgelurden'
}
