export default {
    order: 'Bestellung',
    import: 'Importieren',
    sync: 'Synchronisation',
    settings: 'Einstellungen',
    device: 'Geräte Information',
    error: 'Sie haben unsynchronisierte Bestellungen, bitte synchronisieren Sie zuerst',
    newDocument: 'Klicken Sie auf + um eine neue Bestellung auszugeben',
    piece: 'Stück(e)',
    upload: 'Hochladen',
    uploaded: 'Hochgeladen ',
    from: ' von ',
    noImport: 'Keine Dokumente zum importieren'
}
